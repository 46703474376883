import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Third Party
import Plaatjie from '@ubo/plaatjie'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'

interface BannerDefaultProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
  pageContext?: any
}

const StyledBannerDefault = styled.section``

const StyledPlaatjie = styled(Plaatjie)<{ image: any }>`
  position: absolute !important;
  max-width: 620px;
  top: 0;
  right: 55px;
  z-index: 1;

  @media (max-width: 991px) {
    display: none;
  }

  @media (max-width: 1199px) {
    max-width: 450px;
    right: 25px;
  }
`

const CustomContainer = styled.div`
  @media (min-width: 1400px) {
    max-width: 1450px !important;
  }
`

const Content = styled(ParseContent)`
  padding-top: 55px;
  color: ${(props) => props.theme.color.light};

  & h1,
  h2,
  h3,
  h4 {
    color: ${({ theme }) => theme.color.light};
  }

  & p {
    line-height: 30px;
  }

  @media (max-width: 991px) {
    padding-top: 25px;
  }
`

const Banner = styled.div`
  padding: 90px 60px;
  background-color: ${({ theme }) => theme.color.primary};
  border-radius: 10px;

  @media (max-width: 1199px) {
    padding: 50px 40px;
  }

  @media (max-width: 991px) {
    padding: 60px 30px 15px 30px;
  }

  @media (max-width: 575px) {
    padding: 30px 15px 15px 15px;
  }
`

const StyledBreadcrumb = styled.div`
  nav {
    margin: 0;
  }

  ol {
    display: flex;
    padding: 0;
    color: ${({ theme }) => theme.color.light};

    li {
      list-style: none;

      a {
        color: ${({ theme }) => theme.color.light};

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .breadcrumb__separator {
      margin: 0 10px;
    }
  }
`

const MobileBannerImage = styled.div`
  margin-top: 15px;
  padding: 0 15px 15px 15px;
  background-color: ${({ theme }) => theme.color.grey};
  border-radius: 10px;
`

const MobileStyledPlaatjie = styled(Plaatjie)`
  max-width: 400px;
`

const BannerDefault: React.FC<BannerDefaultProps> = ({
  fields,
  pageContext,
}) => (
  <StyledBannerDefault>
    <CustomContainer className="container">
      <Banner className="position-relative">
        <div className="row">
          <div className="col-lg-5">
            <StyledBreadcrumb>
              <Breadcrumb
                crumbs={pageContext?.breadcrumb.crumbs}
                crumbSeparator=">"
              />
            </StyledBreadcrumb>
            <Content content={fields.description || ''} />
          </div>
        </div>
        <StyledPlaatjie alt="banner" image={fields?.image} />
      </Banner>
      {fields?.image && (
        <MobileBannerImage className="d-lg-none d-flex justify-content-center">
          <MobileStyledPlaatjie alt="banner" image={fields?.image} />
        </MobileBannerImage>
      )}
    </CustomContainer>
  </StyledBannerDefault>
)

export default BannerDefault
